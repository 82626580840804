import React from 'react'

import styles from "./../../../policies-css-module.module.scss";
import '../../../../styles.scss';

import { Layout } from '../../../../components';
import  BigDiscountDaysOffer3   from '../../../../components/policies/bigDiscountDaysOffer/big-discount-days-offer3';

import { Link } from "gatsby"

const BigDiscountDays = () => {
	return (
		<>
			<Layout>
					<div className={styles.container}>
						<div className={styles.link_section}>
							<div className={styles.link_section_div}>
								<Link exact className={styles.tc_links} activeClassName='is-active' to='/promos/bigdiscountdays/tnc-offer3/'>
									BIG DISCOUNT DAYS OFFER 
								</Link>
							</div>
						</div>
						<div className={styles.content_section}>
								<BigDiscountDaysOffer3 />
						</div>
					</div>
			</Layout>
		</>
	)
}

export default BigDiscountDays
